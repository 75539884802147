<template>
  <v-col :class="customClass" :cols="cols" :xs="xs" :sm="sm" :md="md" :lg="lg" :xl="xl" v-bind="{ ...$attrs }">
    <slot />
  </v-col>
</template>
<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "BaseCol",
  props: {
    customClass: {
      type: String,
      default: "pt-0 px-4",
    },
    cols: {
      type: Number,
      default: 12,
    },
    xs: {
      type: Number,
      default: 12,
    },
    sm: {
      type: Number,
      default: 12,
    },
    md: {
      type: Number,
      default: 6,
    },
    lg: {
      type: Number,
      default: 4,
    },
    xl: {
      type: Number,
      default: 3,
    },
  },
});
</script>
